import React, { useRef, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CSearch,
  CBtnList,
  CSectTitle03,
  CRoomDesc,
  CSketchList,
  NavFloor,
  CInlineDefinition,
  CSimpleAccordionList,
  CSectTitle02,
  CHeroImg,
  CFooterSearch,
  CSimpleSlider,
} from '../../../../components/_index';
import infoChoice from '../../../../utils/info-choice';
import infoGet from '../../../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: 'エグゼクティブフロア',
            sub: <>EXECUTIVE FLOOR</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/club_floor/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/club_floor/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <CSearch formId="global" />
      <section className="l_sect u_hiddenWrap">
        <LWrap>
          <p className="c_sectLead">
            ホテル高層階に位置する、快適さを追求したエグゼクティブフロア。
            <br />
            お一人おひとりに寄り添い、心も身体も満たされるサービスをご提供いたします。
          </p>
          <CSectTitle03 title="デラックスツイン・ダブル" />
          <CSimpleSlider
            width="1040px"
            data={[
              {
                img: {
                  src: '/assets/images/stay/floor/club_floor/img_deluxetwindouble02.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/club_floor/img_deluxetwindouble02__sp.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/club_floor/img_deluxetwindouble.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/club_floor/img_deluxetwindouble__sp.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="c_sectLead">
            開放感のあるお部屋でゆったりとおくつろぎいただけます。
            <br />
            ワイヤレス充電サウンドシステムなど、デラックスタイプならではの客室アイテムもご用意し、ワンランク上の上質なステイを叶えます。
          </p>
          <CRoomDesc
            left={
              <>
                <CSketchList
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/club_floor/img_deluxetwin_floor.png',
                        alt: '',
                      },
                    },
                  ]}
                />
              </>
            }
            right={
              <>
                <CSectTitle02
                  title={{
                    en: 'DELUXE TWIN',
                    ja: 'デラックスツイン',
                  }}
                />
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '広さ',
                      text: <>34m²／42m²</>,
                    },
                    {
                      title: '人数',
                      text: '1〜3名',
                    },
                    {
                      title: 'ベッドサイズ',
                      text: 'W120cm×L200cm×2台',
                    },
                  ]}
                />
              </>
            }
          />
          <CRoomDesc
            left={
              <>
                <CSketchList
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/club_floor/img_deluxedouble_floor.png',
                        alt: '',
                      },
                    },
                  ]}
                />
              </>
            }
            right={
              <>
                <CSectTitle02
                  title={{
                    en: 'DELUXE DOUBLE',
                    ja: 'デラックスダブル',
                  }}
                />
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '広さ',
                      text: <>34m²／42m²</>,
                    },
                    {
                      title: '人数',
                      text: '1〜2名',
                    },
                    {
                      title: 'ベッドサイズ',
                      text: 'W200cm×L200cm',
                    },
                  ]}
                />
              </>
            }
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge">
        <LWrap>
          <CSectTitle03 title="スタンダードツイン・ダブル" />
          <CSimpleSlider
            width="1040px"
            data={[
              {
                img: {
                  src: '/assets/images/stay/floor/club_floor/img_standardtwindouble02.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/club_floor/img_standardtwindouble02__sp.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/floor/club_floor/img_standardtwindouble.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/floor/club_floor/img_standardtwindouble__sp.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="c_sectLead">
            シック &
            スタイリッシュをコンセプトとし、ビジネスにも対応できる機能を兼ね備えたお部屋。
            <br />
            余裕のエグゼクティブライフをご満喫いただけます。
          </p>
          <CRoomDesc
            left={
              <>
                <CSketchList
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/club_floor/img_standardtwin_floor.png',
                        alt: '',
                      },
                    },
                  ]}
                />
              </>
            }
            right={
              <>
                <CSectTitle02
                  title={{
                    en: 'STANDARD TWIN',
                    ja: 'スタンダードツイン',
                  }}
                />
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '広さ',
                      text: <>26m²</>,
                    },
                    {
                      title: '人数',
                      text: '1〜2名',
                    },
                    {
                      title: 'ベッドサイズ',
                      text: 'W110cm×L200cm×2台',
                    },
                  ]}
                />
              </>
            }
          />
          <CRoomDesc
            left={
              <>
                <CSketchList
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/club_floor/img_standarddouble_floor.png',
                        alt: '',
                      },
                    },
                  ]}
                />
              </>
            }
            right={
              <>
                <CSectTitle02
                  title={{
                    en: 'STANDARD DOUBLE',
                    ja: 'スタンダードダブル',
                  }}
                />
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '広さ',
                      text: <>26m²</>,
                    },
                    {
                      title: '人数',
                      text: '1〜2名',
                    },
                    {
                      title: 'ベッドサイズ',
                      text: 'W140cm×L200cm／W160cm×L200cm',
                    },
                  ]}
                />
              </>
            }
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge">
        <LWrap>
          <CSectTitle03 title="シングル" />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/floor/club_floor/img_single.png',
              alt: '',
            }}
          />
          <p className="c_sectLead">
            コンパクトでありながら利便性、機能性を兼ね備えた客室。出張など、おひとりの時間をのんびり過ごすのに最適。
          </p>
          <CRoomDesc
            left={
              <>
                <CSketchList
                  data={[
                    {
                      img: {
                        src: '/assets/images/stay/floor/club_floor/img_single_floor.png',
                        alt: '',
                      },
                    },
                  ]}
                />
              </>
            }
            right={
              <>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '広さ',
                      text: <>23m²</>,
                    },
                    {
                      title: '人数',
                      text: ' 1名',
                    },
                    {
                      title: 'ベッドサイズ',
                      text: 'W140cm×L200cm',
                    },
                  ]}
                />
              </>
            }
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <div className="u_mbExLarge">
            <CSectTitle03 title="基本情報" />
            <p className="c_headingLv4 u_fwb">
              チェックイン 15:00 ／<br className="u_sp" /> チェックアウト
              12:00（正午）
            </p>
            <ul
              className="c_noteList u_centerring"
              style={{ width: 'fit-content' }}
            >
              <li>宿泊プランによって異なる場合がございます。</li>
              <li>
                スイート・エグゼクティブフロア限定特典として、ご希望によりチェックアウトを15:00まで無料でご延⻑いたします
                <br />
                （当⽇の状況により、お受けできない場合もございますのであらかじめご了承ください）。
              </li>
            </ul>
          </div>
          <CSimpleAccordionList
            data={[
              {
                title: '客室設備・備品',
                content: (
                  <>
                    <div className="c_bbBox">
                      <p className="">
                        液晶テレビ（地上デジタル放送、BS放送、海外放送／英語、ドイツ語、中国語、韓国語）／シモンズベッド／電気ケトル／冷蔵庫／ミニバー／ネスレ
                        ネスプレッソ／シャワー付トイレ／タオル・バスタオル／ナイトウェア／体重計／脱衣籠／イオンドライヤー／無料ミネラルウォーター／セーフティーボックス／拡大鏡／空気清浄機（加湿機能付）／消臭スプレー
                      </p>
                      <dl className="whiteBox">
                        <dt>スイート・エグゼクティブフロア限定</dt>
                        <dd>
                          ボディウォッシュタオル／バスローブ／ワイヤレス充電サウンドシステム（デラックスタイプ以上）／アイロン・アイロンボード（デラックスタイプ以上）
                        </dd>
                      </dl>
                    </div>
                    <div className="c_bbBox">
                      <dl className="definition">
                        <dt>アメニティグッズ</dt>
                        <dd>
                          L’OCCITANE製ボトルアメニティ（シャンプー、コンディショナー、シャワージェル、ボディミルク）／⽯鹸／カミソリ／ブラシ／ハミガキセット／使い捨てスリッパ
                        </dd>
                      </dl>
                      <dl className="whiteBox">
                        <dt>スイート・エグゼクティブフロア限定</dt>
                        <dd>マウスウォッシュ／基礎化粧品セット</dd>
                      </dl>
                      <ul className="c_noteList u_mt10">
                        <li>
                          アメニティグッズの内容は状況により変更になる場合がございます。あらかじめご了承ください。
                        </li>
                      </ul>
                    </div>
                    <div className="c_bbBox u_pb0">
                      <dl className="definition">
                        <dt>通信環境</dt>
                        <dd>電話／モジュラージャック／Wi-Fi無料接続サービス</dd>
                      </dl>
                      <dl className="whiteBox">
                        <dt>スイート・エグゼクティブフロア限定</dt>
                        <dd>有線放送</dd>
                      </dl>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: '貸出備品',
                content: (
                  <>
                    <div className="c_bbBox u_pb0">
                      <dl className="definition">
                        <dt>寝具</dt>
                        <dd>
                          そばがら枕／低反発枕／抱き枕／毛布／ベッドボード／バスローブ／パジャマ（S、M、Lサイズ）
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>電気、照明器具</dt>
                        <dd>
                          加湿器／ズボンプレッサー／Blu-ray・DVDプレーヤー／蛍光灯スタンド／変圧器／延長コード／各種ソケット／ヘッドホン／くるくるドライヤー／ストレートヘアアイロン
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>浴室備品</dt>
                        <dd>
                          乳液、化粧水、洗顔料、メイク落としの化粧品セット／コットン／ヘアゴム
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>その他貸出備品</dt>
                        <dd>
                          花瓶／将棋／囲碁／アイスノン／体温計／体重計／体脂肪計／着物用敷き／着物掛け（ハンガータイプ）／アイロン・アイロン台／浴槽滑り止めマット／化粧鏡（拡大鏡）／使い捨てマスク／バス椅子・桶
                        </dd>
                      </dl>
                      <dl className="definition u_mb0">
                        <dt>お子様用備品</dt>
                        <dd>
                          詳細は<Link to="/stay/child/">こちら</Link>
                          をご確認ください。
                        </dd>
                      </dl>
                      {/* <ul className="c_noteList">
                        <li>
                          数に限りがございますので、事前にご確認ください。
                        </li>
                      </ul> */}
                      {/* <p className="u_mt15">
                        TEL <a href="tel:0356412958"></a>03-5641-2958　(平日
                        8:00~20:00 / 土・日・祝日 9:00~18:00）
                        <br />
                        宿泊営業課にて承ります。お問合せフォームは
                        <Link to="/contact/">こちら</Link>から
                      </p> */}
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: 'サービス',
                content: (
                  <>
                    <div className="c_bbBox">
                      <ul className="c_circleList u_fs12 innerList">
                        <li>ボイスメッセージサービス</li>
                        <li>
                          <Link to="/private/3mchpxdzfuxz/">
                            ルームサービス
                          </Link>
                        </li>
                        <li>24時間ランドリーサービス</li>
                        <li>クイックチェックアウトサービス</li>
                        <li>電話チェックアウトサービス</li>
                      </ul>
                    </div>

                    <div className="c_bbBox u_pb0">
                      <dl className="definition u_mb0">
                        <dt>バリアフリー</dt>
                        <dl className="definition">
                          <dt>室内用福祉機</dt>
                          <dd>
                            耳の不自由な方のために振動式ドアノックセンサー、アラームセンサーなどをご用意しております。
                          </dd>
                        </dl>
                        <ul className="c_noteList">
                          <li>
                            数に限りがございますので、事前にご確認ください。
                          </li>
                        </ul>
                        <p className="u_mt15 u_mb0">
                          宿泊営業課
                          <br />
                          TEL <a href="tel:0356412958">03-5641-2958</a>
                          <br />
                          （平日 8:00~20:00/土・日・祝日 9:00~18:00）
                          <br />
                          お問合せフォームは
                          <Link
                            to="https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1"
                            target="_blank"
                          >
                            こちら
                          </Link>
                        </p>
                      </dl>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: 'スイート・エグゼクティブフロア限定特典',
                content: (
                  <>
                    <div className="c_bbBox u_pb0">
                      <dl className="definition">
                        <dt>エグゼクティブラウンジのご利用</dt>
                        <dd>
                          チェックイン・アウトなど各種お手続き／ご朝食／ティータイム／カクテルタイム
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>館内施設のご優待</dt>
                        <dd>
                          5F ご宿泊者専用ジム 無料／5F LA VILLA Spa by
                          L’OCCITANE Tokyo ご優待／18F ミーティングルーム
                          2時間無料
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>アニバーサリープランナー</dt>
                        <dd>
                          ご宿泊日の5日前までご要望を承りますので、お気軽にお申し付けください。
                        </dd>
                      </dl>
                      <dl className="definition">
                        <dt>レイトチェックアウト（15:00まで）</dt>
                        <dd>
                          ※リクエストとして承ります。※当日の状況により、お受けできない場合もございます。また、ご予約のプランによっては、承れない場合がございます。
                        </dd>
                      </dl>
                    </div>
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: 'エグゼクティブラウンジ',
                link: {
                  href: '/stay/floor/lounge/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <NavFloor />
      <LPlan />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CFooterSearch formId="footer" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
